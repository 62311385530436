import { useEffect, useState } from 'react';
import axios from 'axios';
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import Switch from '@mui/material/Switch';
// Auth service
import { auth } from "../../utils/firebase.utils";
import { useAuthState } from "react-firebase-hooks/auth";
import SoftAlert from "components/SoftAlert";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import Icon from "@mui/material/Icon";

function NotificationsUI() {
    const [data, setData] = useState([]);    
    const [user, loading] = useAuthState(auth);
    const [showDisabled, setShowDisabled] = useState(false);

    const [webSocketClient, setWebSocketClient] = useState();  
    const { WebPubSubClient } = require("@azure/web-pubsub-client");

    async function startWebSocket() {
        // Instantiate the client object. 
        // <client-access-url> is copied from Azure portal mentioned above.
        const client = new WebPubSubClient({
            getClientAccessUrl: async () => {
                let res = await axios.get(`socket?hub=account_${user.uid}`);
              return res.data.url;
            }
          });
  
        client.start();
        client.joinGroup(`notifications`);
        setWebSocketClient(client);
    }

    useEffect(() => {
        updateData();
        startWebSocket();
    }, []);

    async function updateData() {
        const notifications = await axios.get(`notifications/${user.uid}`);   
        setData(notifications.data);
    }

    async function onDismiss(alertId) {
        const body = {
            "alertId": alertId
        };
        const res = await axios.post(`notifications/disable`, body);
        webSocketClient.sendToGroup("notifications", "update", "text");
    }

    async function dismissAll() {
        const body = {
            "alertIds": data.map((d) => d.id)
        };
        const res = await axios.post(`notifications/disable/bulk`, body);
        updateData();
        webSocketClient.sendToGroup("notifications", "update", "text");
    }

    function formatDate(datetime) {
        console.log(datetime);
        var date = new Date(datetime);
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return (date.getMonth()+1) + "/" + date.getDate() + "/" + date.getFullYear() + "  " + strTime;
      }

    return (
        <DashboardLayout>
            <DashboardNavbar title="Notifications" />
            <Grid container>
                
            <Grid item xs={8} md={8} lg={8} key={`switch`} mb={2}>
                <SoftBox display="flex" justifyContent="space-between">
                    <SoftBox >
                        <Switch
                            checked={showDisabled}
                            onChange={(e) => setShowDisabled(e.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        <SoftBox ml={2} display="inline-block">
                            <SoftTypography variant="button">
                                Show Dismissed Notifications
                            </SoftTypography>
                        </SoftBox>
                    </SoftBox>
                    <SoftBox >
                        <SoftButton variant="contained" color="dark" onClick={() => dismissAll()}>
                            Clear All
                        </SoftButton>
                    </SoftBox>
                </SoftBox>
            </Grid>
                {data && data.map((alert, i) => {
                    if(alert.status === 'disabled' && showDisabled === false)
                    {
                        return;
                    }
                    return (                
                    <Grid item xs={8} md={8} lg={8} key={`tool-card-grid-${alert.id}`} sx={{padding:'0'}}>
                        <SoftAlert color="secondary" dismissible onDismiss={() => onDismiss(alert.id)} link={alert.link ? alert.link : null}>                   
                            <Icon fontSize="medium">info</Icon>&nbsp;
                            <SoftBox display="flex" flexDirection="column">
                            <SoftTypography variant="caption" color="white">
                                {formatDate(alert.datetime_alerted)}
                            </SoftTypography>
                            <SoftTypography variant="body2" color="white">
                                {alert.alert}
                            </SoftTypography>
                            </SoftBox>
                        </SoftAlert>
                    </Grid>                
                )})}
            </Grid>
        </DashboardLayout>
    );
}

export default NotificationsUI;