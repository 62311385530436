/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Settings page components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Link from "@mui/material/Link";
// Auth service
import { auth } from "../../utils/firebase.utils";
import { useAuthState } from "react-firebase-hooks/auth";
import axios from 'axios';

function Stripe() {
    
  const [user, loading] = useAuthState(auth);

  console.log(axios.defaults.baseURL);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={4}>
        <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
            <h2>Purchase</h2>
            </Grid>
            <Grid item xs={12} lg={12}>
            <h4>Embedded</h4>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Link href="/stripe/embedded?priceId=price_1QQCAV067GdU2VBO5LKrlJtX" color="inherit">
                Pro Plan - Monthly
              </Link>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Link href="/stripe/embedded?priceId=price_1QQFZ4067GdU2VBOeueQpeEE" color="inherit">
                Pro Plan - Yearly
              </Link>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Link href="/stripe/embedded?priceId=price_1QQCDQ067GdU2VBOs4ovim58" color="inherit">
                Premium Plan - Monthly
              </Link>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Link href="/stripe/embedded?priceId=price_1QQFaa067GdU2VBO8mHQaZvr" color="inherit">
                Premium Plan - Yearly
              </Link>
            </Grid>
            <Grid item xs={12} lg={12}>
            <h4>Hosted</h4>
            </Grid>
            <Grid item xs={12} lg={12}>
              <form action={`${axios.defaults.baseURL}/stripe/create-checkout-session/hosted`} method="POST">
                <input type="hidden" value="price_1QQCAV067GdU2VBO5LKrlJtX" name="priceId"></input>
                <input type="hidden" value={user.uid} name="uid"></input>
                <button type="submit">
                  Pro Plan - Monthly
                </button>
              </form>
            </Grid>
            <Grid item xs={12} lg={12}>
              <form action={`${axios.defaults.baseURL}/stripe/create-checkout-session/hosted`} method="POST">
                <input type="hidden" value="price_1QQFZ4067GdU2VBOeueQpeEE" name="priceId"></input>
                <input type="hidden" value={user.uid} name="uid"></input>
                <button type="submit">
                Pro Plan - Yearly
                </button>
              </form>
            </Grid>
            <Grid item xs={12} lg={12}>
              <form action={`${axios.defaults.baseURL}/stripe/create-checkout-session/hosted`} method="POST">
                <input type="hidden" value="price_1QQCDQ067GdU2VBOs4ovim58" name="priceId"></input>
                <input type="hidden" value={user.uid} name="uid"></input>
                <button type="submit">
                Premium Plan - Monthly
                </button>
              </form>
            </Grid>
            <Grid item xs={12} lg={12}>
              <form action={`${axios.defaults.baseURL}/stripe/create-checkout-session/hosted`} method="POST">
                <input type="hidden" value="price_1QQFaa067GdU2VBO8mHQaZvr" name="priceId"></input>
                <input type="hidden" value={user.uid} name="uid"></input>
                <button type="submit">
                  Premium Plan - Yearly
                </button>
              </form>
            </Grid>
            <Grid item xs={12} lg={12}>
            <h2>Customer Settings</h2>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Link href="https://billing.stripe.com/p/login/test_7sIcQk9Iea3behW8ww" color="inherit" target="_blank">
                Manage Subscription
              </Link>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Link href="/stripe/subscription" color="inherit" target="_blank">
                See Subscription
              </Link>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Link href="/stripe/invoices" color="inherit" target="_blank">
                See Invoices
              </Link>
            </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Stripe;
