/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Fade from "@mui/material/Fade";
import Link from "@mui/material/Link";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Custom styles for the SoftAlert
import SoftAlertRoot from "components/SoftAlert/SoftAlertRoot";
import SoftAlertCloseIcon from "components/SoftAlert/SoftAlertCloseIcon";

function SoftAlert({ color, dismissible, children, onDismiss, link, ...rest }) {
  const [alertStatus, setAlertStatus] = useState("mount");

  const handleAlertStatus = () => {
    if(onDismiss) onDismiss()
    setAlertStatus("fadeOut")
  };

  const alertContent = () => {
    let content = (
      <SoftBox display="flex" alignItems="center" color="white">
        {children}
      </SoftBox>
    );

    if(link) {
      content = (
        <Link href={link} color="inherit" onClick={handleAlertStatus}>
          {content}
        </Link>
      );
    }

    return content;
  }

  // The base template for the alert
  const alertTemplate = (mount = true) => (
    <Fade in={mount} timeout={300}>
        <SoftAlertRoot ownerState={{ color }} {...rest}>
          {alertContent()}
          {dismissible ? (
            <SoftAlertCloseIcon onClick={mount ? handleAlertStatus : null}>
              &times;
            </SoftAlertCloseIcon>
          ) : null}
        </SoftAlertRoot>
    </Fade>
  );

  switch (true) {
    case alertStatus === "mount":
      return alertTemplate();
    case alertStatus === "fadeOut":
      setTimeout(() => setAlertStatus("unmount"), 400);
      return alertTemplate(false);
    default:
      alertTemplate();
      break;
  }

  return null;
}

// Setting default values for the props of SoftAlert
SoftAlert.defaultProps = {
  color: "info",
  dismissible: false,
};

// Typechecking props of the SoftAlert
SoftAlert.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  dismissible: PropTypes.bool,
  onDismiss: PropTypes.func,
  link: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default SoftAlert;
