/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Settings page components
import ChangePassword from "pages/account/settings/components/ChangePassword";
import UserManagement from "pages/account/settings/components/UserManagement";
import Integrations from "pages/account/settings/components/Integrations";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";

import { auth } from "../../../utils/firebase.utils";
import { useAuthState } from "react-firebase-hooks/auth";
import axios, {AxiosError} from 'axios';

function Settings() {
  const [firebaseUser, loading] = useAuthState(auth);
  const [userDetails, setUserDetails] = useState();
  
  useEffect(() => {
    (async function() {      
      if(firebaseUser) {
        const details = await axios.get(`/users/firebase/${firebaseUser.uid}`);
        console.log(details.data);
        setUserDetails(details.data);
      }
    })();
  }, [firebaseUser]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={4}>
        <Grid container spacing={3}>
          {userDetails && ['admin','manager'].includes(userDetails.type) &&
            <Grid item xs={12} lg={12}>
              <SoftBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <UserManagement />
                  </Grid>
                </Grid>
              </SoftBox>
            </Grid>
          }
          <Grid item xs={12} lg={12}>
            <SoftBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Integrations />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
          {firebaseUser && firebaseUser.providerData[0].providerId === "password" && 
            <Grid item xs={12} lg={12}>
              <SoftBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <ChangePassword />
                  </Grid>
                </Grid>
              </SoftBox>
            </Grid>
          }
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Settings;
