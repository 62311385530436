import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function WordpressIntegrationDocs() {
    return (
        <DashboardLayout>
            <DashboardNavbar title="Wordpress Integration Documentation"/>
            
            <SoftBox mt={1} mb={1} width="50%">
                <SoftTypography variant="body1">
                    This guide goes over the process of setting up an App password to enable calling the Wordpress site API.
                </SoftTypography>

                
                <SoftBox mt={3}>
                    <SoftTypography variant="h3">
                        Create New User (optional)
                    </SoftTypography>

                    <SoftTypography variant="body1">
                        If desired, create a new wordpress user for the API to use. This can be done to limit the permissions the API consumer will have. If creating a new user for this, the Author role is recommended.
                    </SoftTypography>


                    <SoftTypography variant="body1">
                        To create a new user, click the <b>Users</b> link in the Wordpress dashboard sidebar, then select <b>Add New User</b>. Fill in the details, and make sure to select a role that has permissions to create content on the site. One of <b>Author, Editor, Administrator, or Contributor</b>. The <b>Author</b> role is recommended here. 
                    </SoftTypography>
                </SoftBox>
                
                <SoftBox mt={3}>
                    <SoftTypography variant="h3">
                        Create Application Password
                    </SoftTypography>
                        
                    <SoftTypography variant="body1">
                        To create an application password, click the <b>Users</b> link in the Wordpress dashboard sidebar, then select <b>All Users</b>. Find the user you want to use, and then click on the <b>Edit</b> button that appears underneath the user name.
                    </SoftTypography>
                    
                    <SoftTypography variant="body1">
                        On the edit page, scroll down until you see the <b>Application Passwords</b> section. Enter a descriptive name in the <b>New Application Password Name</b> field, and then click the <b>Add New Application Password</b> button. You should then see the new password output to the screen. Copy this value and store it in a secure location.
                    </SoftTypography>
                </SoftBox>
                
                <SoftBox mt={3}>
                    <SoftTypography variant="h3">
                        Add App Password to PromptCore System
                    </SoftTypography>
                        
                    <SoftTypography variant="body1">
                        To add the app password to the PromptCore system, some MySQL tables will need to be updated on the pc-cron MySQL server.
                    </SoftTypography>

                    <SoftTypography variant="body1">
                        First, ensure that the Wordpress site’s domain name is configured in the <b>wordpress_configs.sites table</b>.
                    </SoftTypography>
                    
                    <SoftTypography variant="body1">
                        Then, add the application password credentials to the <b>wordpress_configs.site_application_creds</b> table, where the <b>app_user</b> value is the name of the Wordpress user the app password was created for. 
                    </SoftTypography>
                </SoftBox>
            </SoftBox>
        </DashboardLayout>
    )
}

export default WordpressIntegrationDocs;