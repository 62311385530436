import SoftButton from "../SoftButton";
import SoftInput from '../SoftInput';
import SoftBox from '../SoftBox';
import SoftFormikSelect from '../SoftFormikSelect';
import SoftTypography from '../SoftTypography';
import Switch from "@mui/material/Switch";
import axios from 'axios';
import { useEffect, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

import { auth } from "../../utils/firebase.utils";
import { useAuthState } from "react-firebase-hooks/auth";

// formik components
import { Formik, Form, Field } from "formik";

function ToolRunForm({ flowId, previousRunId, selectedPermalink, handleBack, isRedditFlow, onSubmit, initialValuesOverride }) {
    const [flowInputs, setFlowInputs] = useState([])
    const [disableSubmit, setDisableSubmit] = useState(false)
    const [firebaseUser, loading] = useAuthState(auth);
    const [initialValues, setInitialValues] = useState({});

    const handleSubmit = async (values) => {

        if(!values['email'] && firebaseUser) values['email'] = firebaseUser.email;

        setDisableSubmit(true);
        const data = {
            uid: firebaseUser?.uid,
            flowId: flowId,
            variables: JSON.stringify(values)
        }
        onSubmit(data);
    }

    useEffect(() => {
        if(flowId) {
            (async function() {
                let previousRunValues = null;
                if(previousRunId && flowId) previousRunValues = await axios.get(`flows/previousRunValues/${previousRunId}/${flowId}`);
                const flowParametersResponse = await axios.get(`flows/parameters/${flowId}`);
                setFlowInputs(flowParametersResponse.data)
                setDisableSubmit(false);
            })();
        }
    }, [flowId, previousRunId, selectedPermalink, firebaseUser?.email])


    useEffect(() => {
        let iValues = {};
        for(const input of flowInputs) {
            if(initialValuesOverride && input['name'] in initialValuesOverride)
            {
                iValues[input['name']] = initialValuesOverride[input['name']] ?? '';
            }
            else
            {
                iValues[input['name']] = input['default_value'] ?? '';
            }
        }  
        setInitialValues(iValues);   
    }, [flowInputs, initialValuesOverride])

    const renderInput = (variable, setFieldValue) => {

        // TEXTAREA
        if(variable.input_type_id == 2)
        {
            return (
                <Field
                    as={SoftInput}
                    multiline
                    rows={5}
                    name={variable.name}
                    required={variable.is_optional == 0}
                />
            )
        }

        // NUMBER
        if(variable.input_type_id == 3)
        {
            return (
                <Field
                    as={SoftInput}
                    type="number"
                    name={variable.name}
                    required={variable.is_optional == 0}
                />
            )
        }

        // SWITCH
        if(variable.input_type_id == 4)
        {
            return (
                <Field
                    as={Switch}
                    name={variable.name}
                    required={variable.is_optional == 0}
                />
            )
        }

        // SELECT
        if(variable.input_type_id == 5)
        {
            return (
                <Field
                    component={SoftFormikSelect}
                    options={variable.settings.options ?? []}
                    name={variable.name}
                    required={variable.is_optional == 0}
                    setFieldValue={setFieldValue}
                />
            )
        }

        // YEAR
        if(variable.input_type_id == 6)
        {
            let options = [];
            let endYear = 2000;
            let currentYear = new Date().getFullYear();
            while (endYear <= currentYear) {
                options.push({
                    'label':currentYear,
                    'value':currentYear
                });
                currentYear = currentYear - 1;
            }
            return (
                <Field
                    component={SoftFormikSelect}
                    options={options}
                    name={variable.name}
                    required={variable.is_optional == 0}
                    setFieldValue={setFieldValue}
                />
            )
        }

        return (
            <Field
                as={SoftInput}
                type="text"
                name={variable.name}
                required={variable.is_optional == 0}
            />
        )
    }

    const formatLabel = (label, is_optional) => {
        // replace underscores with spaces, and convert to titlecase
        let l = label.toLowerCase().split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
        if(is_optional == 0)
        {
            l = l + "*";
        }
        return l;
    }

    return (
        <Formik
              initialValues={initialValues}
              enableReinitialize
              onSubmit={handleSubmit}
            >
            {({ setFieldValue }) => (
              <Form id="tool-inputs" autoComplete="off">
                <SoftTypography
                    component="label"
                    variant="h5"
                    fontWeight="bold"
                    textTransform="capitalize"
                >
                    Input Values
                </SoftTypography>
                <Grid container spacing={2}>
                    {flowInputs && flowInputs.map((variable) => {
                        return (
                            <Grid item xs={12} sm={12} md={variable.size_md ?? 12} key={`${variable.name}`}>
                                <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                                    <SoftBox mt={1} mb={1} lineHeight={0} display="inline-block">
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            {formatLabel(variable.name, variable.is_optional)}
                                        </SoftTypography>
                                        {variable.helper_text &&
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize"
                                                sx={{
                                                    fontSize: "11px"
                                                }}
                                            >
                                                &nbsp;{variable.helper_text}
                                            </SoftTypography>    
                                        }
                                    </SoftBox>
                                    {renderInput(variable, setFieldValue)}
                                </SoftBox>
                            </Grid>
                        );
                    })}
                </Grid>
                <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">
                    {isRedditFlow ? (
                        <SoftButton variant="gradient" color="secondary" onClick={handleBack}>
                            back
                        </SoftButton>) : <SoftBox />}
                    <SoftButton
                        variant="gradient"
                        color="dark"
                        type="submit"
                        disabled={disableSubmit}
                        endIcon={disableSubmit && <CheckIcon size={18} />}
                    >
                        {disableSubmit ? 'Queued' : 'Submit'}
                    </SoftButton>
                </SoftBox>
            </Form>
            )}
        </Formik>
    )
}

ToolRunForm.propTypes = {
    flowId: PropTypes.number,
    previousRunId: PropTypes.number,
    selectedPermalink: PropTypes.string,
    handleBack: PropTypes.any,
    isRedditFlow: PropTypes.any,
    onSubmit: PropTypes.func.isRequired,
    initialValuesOverride: PropTypes.object,
}

export default ToolRunForm;