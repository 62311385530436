import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import SoftBadge from "../../components/SoftBadge";
import Modal from '@mui/material/Modal';
import Icon from "@mui/material/Icon";
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import moment from 'moment-timezone';
import JsonView from 'react18-json-view'
import Grid from "@mui/material/Grid";
import SoftButton from "../../components/SoftButton";
import SoftPagination from "../../components/SoftPagination";
import DataTable from "../../components/Tables/DataTable";
import 'react18-json-view/src/style.css'
import { useParams } from 'react-router-dom';
import RunnerModal from 'components/RunnerModal';
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Tooltip from "@mui/material/Tooltip";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import logoPromptcore from "assets/images/small-logos/promptcore-logo.png";
import SoftAvatar from "components/SoftAvatar";
import { CSVLink } from 'react-csv';

const MODAL_TYPE_ARTICLE = 'article';
const MODAL_TYPE_BATCH = 'batch';
const MODAL_TYPE_SPLIT = 'split';

function ResultsUI() {
    const [data, setData] = useState(null);
    const [open, setOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [paginatedContent, setPaginatedContent] = useState([]);
    const [modalTitle, setModalTitle] = useState('');
    const [modalSize, setModalSize] = useState('70%');
    const [selectedFlow, setSelectedFlow] = useState(null);
    const [previousRunId, setPreviousRunId] = useState(null);
    const [flowRunnerOpen, setFlowRunnerOpen] = useState(false);
    const [activePage, setPage] = useState(0);
    const [htmlViewOpen, setHtmlViewOpen] = useState(false);
    const [htmlNode, setHtmlNode] = useState('');

    const { runId } = useParams();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const { WebPubSubClient } = require("@azure/web-pubsub-client");

    const columns = [
        { 
            accessorKey: 'logo', 
            header: 'Logo',
            cell: ({ cell, row }) => {
                return (
                    <SoftAvatar
                        src={logoPromptcore}
                        alt='tool-logo'
                        size="sm"
                        variant="rounded"
                        bgColor='dark'
                        sx={{ p: 1 }}
                    />
                )
            },
            size: 100,
            enableColumnFilter: false,
        },
        { accessorKey: 'flow_name', header: 'Tool Name', size: 300 },
        { 
            accessorKey: 'id',
            header: 'Run ID',
            size: 125,
            filterFn: (row, columnId, filterValue) => {                
                return row?.original?.id == filterValue;
            },
        },
        { 
            accessorKey: 'type_badge',
            header: 'Type',
            cell: ({ cell, row }) => {
                return <>{row.original.type_badge}</>
            },
            filterFn: (row, columnId, filterValue) => {                
                return row?.original?.type?.includes(filterValue);
            },
        }, 
        { accessorKey: 'datetime', header: 'Datetime', size: 200 },
        { accessorKey: 'duration', header: 'Duration (s)', size: 175 },
        { accessorKey: 'tokens', header: 'Tokens' },
        { accessorKey: 'estimated_llm_cost', header: 'LLM Cost' },
        { 
            accessorKey: 'status_badge',
            header: 'Status',
            cell: ({ cell, row }) => {
                return <>{row.original.status_badge}</>
            },
            filterFn: (row, columnId, filterValue) => {                
                return row?.original?.status?.includes(filterValue);
            },
        },        
        { 
            accessorKey: 'outputs_modal', 
            header: 'Outputs',
            cell: ({ cell, row }) => {
                return <>{row.original.outputs_modal}</>
            },
            enableColumnFilter: false,
        },
        { 
            header: "action",
            accessorKey: "action",
            cell: ({ cell, row }) => {
                return <>{row.original.action}</>
            },
            enableColumnFilter: false,
            size: 250,
        },
    ]

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 400,
        maxHeight: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'scroll',
    };

    const closeModal = () => {
        handleClose();
        setModalContent('');
        setPaginatedContent([]);
        setPage(0)
    }

    const showHtmlNode = (html) => {
        setHtmlNode(html);
        setHtmlViewOpen(true);
    }

    const closeHtmlViewModal = () => {
        setHtmlNode('');
        setHtmlViewOpen(false);
    }

    const formatLabel = (label) => {
        // replace underscores with spaces, and convert to titlecase
        return label.toLowerCase().split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
    }

    const openPaginatedModal = useCallback((title, flowOutputs, type, expectedOutputs = [], size = '70%') => {
        setModalSize(size)
        handleOpen();
        const tempPaginatedContent = [];
        for(const flowOutput in flowOutputs) {
            if(expectedOutputs.includes(flowOutput) || type != MODAL_TYPE_ARTICLE) {
                tempPaginatedContent.push({
                    content: type == MODAL_TYPE_ARTICLE ? flowOutputs[flowOutput] : flowOutputs[flowOutput],
                    header: flowOutput,
                    type: type,
                })
            }
        }
        setPaginatedContent(tempPaginatedContent)
        if(tempPaginatedContent.length < 1) {
            setModalContent(<JsonView src={flowOutputs} theme={'default'} />)
        } else {
            setModalContent('')
        }
        setModalTitle(title)
    }, []);

    const openSplitPaginatedModal = useCallback((title, flowInputs, flowOutputs, size = '70%') => {
        setModalSize(size)
        handleOpen();
        const tempPaginatedContent = [];        
        for(let i = 0; i < flowOutputs.length; i++) {            
            tempPaginatedContent.push({
                leftContent: flowInputs[i],
                rightContent: flowOutputs[i],
                header: String(i),
                type: MODAL_TYPE_SPLIT,
            })
        }
        setPaginatedContent(tempPaginatedContent)
        if(tempPaginatedContent.length < 1) {
            setModalContent(
                <JsonView 
                    src={flowOutputs} 
                    theme={'default'} 
                />
            )
        } else {
            setModalContent('')
        }
        setModalTitle(title)
    }, []);

    function escapeDoubleQuotes(arr) {
        if(Array.isArray(arr) == false)
        {
            return arr;
        }
        return arr.map(obj => {
          // Create a new object to store the escaped values
          let escapedObj = {};
          
          // Iterate over each key-value pair in the object
          for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
              let value = obj[key];
              
              // Check if the value is a string, and escape double quotes
              if (typeof value === 'string') {
                escapedObj[key] = value.replace(/"/g, '""');
              } else {
                escapedObj[key] = value; // If not a string, leave it as is
              }
            }
          }
          
          return escapedObj;
        });
      }

      const openModal = (content, title, size = '70%') => {
        setModalSize(size)
        handleOpen();
        setModalContent(content)
        setModalTitle(title)
    }

    async function startWebSocket() {
        // Instantiate the client object. 
        // <client-access-url> is copied from Azure portal mentioned above.
        const client = new WebPubSubClient({
            getClientAccessUrl: async () => {
                let res = await axios.get(`socket?hub=results`);
              return res.data.url;
            }
          });

        client.start();

        client.joinGroup("ResultsUpdate");

        client.on("server-message", (e) => {
            console.log(`Received server message: ${e.message.data}`);
            updateData();
        });

        client.on("group-message", (e) => {
            console.log(`Received group message from ${e.message.group}: ${e.message.data}`);
            updateData();
        });
    }

      async function updateData() {
        const flowRuns = await axios.get('flows/runs');
        const flowbatchRuns = await axios.get('flows/batchRuns');
        const articleFlows = await axios.get('flows/articleFlows');
        const articleFlowMap = {}
        for(const articleFlow of articleFlows.data) {
          articleFlowMap[articleFlow.flow_id] = articleFlow.expected_outputs;
        }
        for(const flowRun of flowRuns.data) {            
          if(!flowRun.outputs) {
              flowRun.outputs_modal = null;
          } else if(articleFlowMap[flowRun.flow_id]) {
              flowRun.outputs_modal = <SoftButton onClick={() => openPaginatedModal('Articles', flowRun.outputs, MODAL_TYPE_ARTICLE, articleFlowMap[flowRun.flow_id])}>Details</SoftButton>;
          } else {
              flowRun.outputs_modal = <SoftButton onClick={() => openModal(<JsonView src={flowRun.outputs} theme={'default'} />, 'Outputs')}>Details</SoftButton>;
          }
          const statusBadgeText = flowRun.status;
          let statusBadgeColor = 'primary';
          switch(flowRun.status) {
              case 'completed':
                  statusBadgeColor = 'success';
                  break;
              case 'error':
                  statusBadgeColor = 'error';
                  break;
              case 'running':
                  statusBadgeColor = 'secondary';
                  break;
              case 'queued':
                  statusBadgeColor = 'info';
                  break;
          }
          flowRun.type = 'single';
          flowRun.type_badge = <SoftBadge variant="contained" color="secondary" size="md" badgeContent={flowRun.type} container />;
          flowRun.status_badge = <SoftBadge variant="contained" color={statusBadgeColor} size="md" badgeContent={statusBadgeText} container />;
          if(flowRun.datetime) flowRun.datetime = moment(new Date(flowRun.datetime)).format("YYYY-MM-DD HH:mm:ss");
          if(flowRun.datetime_completed) {
              flowRun.datetime_completed = moment(new Date(flowRun.datetime_completed));
              flowRun.duration = flowRun.datetime_completed.diff(moment(new Date(flowRun.datetime)), 'seconds');                
          } else {
              flowRun.duration = "0";
          }
          flowRun.action = ActionCell(flowRun.inputs, flowRun.flow_snapshot, flowRun.run_variables, openModal)
        }
        for(const batchRun of flowbatchRuns.data) {
          if(!batchRun.outputs) {
              batchRun.outputs_modal = null;
          } 
          else {
              batchRun.outputs_modal = <SoftButton onClick={() => openSplitPaginatedModal('Results', batchRun.inputs, batchRun.outputs)}>Details</SoftButton>;
          }
          const statusBadgeText = batchRun.status;
          let statusBadgeColor = 'primary';
          switch(batchRun.status) {
              case 'completed':
                  statusBadgeColor = 'success';
                  break;
              case 'error':
                  statusBadgeColor = 'error';
                  break;
              case 'running':
                  statusBadgeColor = 'secondary';
                  break;
              case 'queued':
                  statusBadgeColor = 'info';
                  break;
          }  
          batchRun.type = 'batch';
          batchRun.type_badge = <SoftBadge variant="contained" color="secondary" size="md" badgeContent={batchRun.type} container />;        
          batchRun.status_badge = <SoftBadge variant="contained" color={statusBadgeColor} size="md" badgeContent={statusBadgeText} container />;
          if(batchRun.datetime) batchRun.datetime = moment(new Date(batchRun.datetime)).format("YYYY-MM-DD HH:mm:ss");
          if(batchRun.datetime_completed) {
              batchRun.datetime_completed = moment(new Date(batchRun.datetime_completed));
              batchRun.duration = batchRun.datetime_completed.diff(moment(new Date(batchRun.datetime)), 'seconds');                
          } else {
              batchRun.duration = "0";
          }
          const csvContent = [];        

          if('outputs' in  batchRun && Array.isArray(batchRun.outputs))
          {
            for(let i = 0; i < batchRun.outputs.length; i++) {            
                csvContent.push(
                    Object.assign({}, batchRun.inputs[i], batchRun.outputs[i])
                )
            }
          }
          
          batchRun.action = ActionCell(batchRun.inputs, batchRun.flow_snapshot, batchRun.run_variables, openModal, MODAL_TYPE_BATCH, batchRun.status == 'completed' ? csvContent : null)
        }          
        setData([ ...flowRuns.data, ...flowbatchRuns.data]);
      }


    function ActionCell(inputs, snapshot, runVariables, openModal, type = null, csvData = null) {        
        return (
          <SoftBox display="flex" alignItems="center">
            <SoftBox mx={1}>
                <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} onClick={() => {if(inputs) {type == MODAL_TYPE_BATCH ? openPaginatedModal('Inputs', inputs, MODAL_TYPE_BATCH) : openModal(<JsonView src={inputs} theme={'default'} />, 'Inputs')}}}>
                <Tooltip title="Inputs" placement="top">
                    <Icon>checklist</Icon>
                </Tooltip>
                </SoftTypography>
            </SoftBox>
            <SoftBox mx={1}>
                <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} onClick={() => {if(runVariables) {type == MODAL_TYPE_BATCH ? openPaginatedModal('Run Variables', runVariables, MODAL_TYPE_BATCH) : openModal(<JsonView src={runVariables} theme={'default'} />, 'Run Variables')}}}>
                <Tooltip title="Run Variables" placement="left">
                    <Icon>summarize</Icon>
                </Tooltip>
                </SoftTypography>
            </SoftBox>
            {snapshot && (   
                <SoftBox mx={1}> 
                    <Link href={`${snapshot}`} target="_blank" rel="noopener">
                        <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
                            <Tooltip title="Flow Snapshot" placement="top">
                                <Icon>camera</Icon>
                            </Tooltip>
                        </SoftTypography>
                    </Link>
                </SoftBox>
            )}
            {csvData && (
                <SoftBox mx={1}>
                    <CSVLink 
                        data={escapeDoubleQuotes(csvData)}
                        filename={"batch_run_results.csv"}
                        enclosingCharacter={`"`}
                    > 
                        <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
                            <Tooltip title="Download CSV" placement="top">
                                <Icon>download</Icon>
                            </Tooltip>
                        </SoftTypography>
                    </CSVLink>
                </SoftBox>
            )}
          </SoftBox>
        );
      }

    useEffect(() => {
        const myDiv = document.getElementById('article-viewer-container');
        if(myDiv) {
            myDiv.scrollTop = 0;
        }
    }, [activePage])

    useEffect(() => {
        updateData();
        startWebSocket();
    }, [])

    return (
        <DashboardLayout>
            <DashboardNavbar title="Results" />
            <Modal open={htmlViewOpen} onClose={() => closeHtmlViewModal()} aria-labelledby={modalTitle}>
                <Box sx={style}>
                    <div style={{ maxHeight: '80%', overflow: 'overlay' }}>
                        <div style={{ fontSize: 24 }}>
                            <div dangerouslySetInnerHTML={{__html: htmlNode}}></div>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal open={open} onClose={() => closeModal()} aria-labelledby={modalTitle}>
                <Box sx={style}>
                    {paginatedContent.length > 0 ? (
                        <>
                            <div style={{ maxHeight: '55vh', overflow: 'overlay' }} id='article-viewer-container'>
                                <div style={{ textAlign: 'center', fontSize: 24 }}>
                                    {formatLabel(paginatedContent[activePage].header)}
                                </div>
                                {paginatedContent[activePage].type == MODAL_TYPE_BATCH ? <JsonView src={paginatedContent[activePage].content} theme={'default'} /> : null}
                                {paginatedContent[activePage].type == MODAL_TYPE_ARTICLE ? <div dangerouslySetInnerHTML={{__html: paginatedContent[activePage].content}}></div> : null}
                                {paginatedContent[activePage].type == MODAL_TYPE_SPLIT ? (
                                    <Grid container flexDirection="column" pt={5}>
                                        <SoftTypography
                                            variant="h4"
                                            textTransform="capitalize"
                                            fontWeight="bold"
                                        >
                                            Inputs:
                                        </SoftTypography>
                                        <JsonView src={paginatedContent[activePage].leftContent} theme={'default'} />
                                        <SoftTypography
                                            variant="h4"
                                            textTransform="capitalize"
                                            fontWeight="bold"
                                        >
                                            Outputs:
                                        </SoftTypography>
                                        <JsonView 
                                            src={paginatedContent[activePage].rightContent} 
                                            theme={'default'} 
                                            customizeNode={params => {
                                                if (params.indexOrName && params.indexOrName.toLowerCase().includes('article'))
                                                    return () => {
                                                    return (
                                                        <span className="json-view--string cursor-pointer">
                                                        {'"' + params.node.substring(0,100) + '..."'}
                                                        <SoftButton onClick={() => showHtmlNode(params.node)} size="small" iconOnly marginLeft="5rem">
                                                            <Icon>open_in_new</Icon>
                                                        </SoftButton>
                                                        </span>
                                                    )
                                                }
                                            }}
                                        />
                                    </Grid>) : null}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2vh' }}>
                                <SoftPagination size="small">
                                    <SoftPagination item onClick={() => {if(activePage > 0) {setPage(activePage - 1)}}} disabled={activePage == 0}>
                                        <Icon>keyboard_arrow_left</Icon>
                                    </SoftPagination>
                                    {paginatedContent.map((object, i) => <SoftPagination item active={i == activePage} key={`pagination-page-${i}`} onClick={() => setPage(i)} >{i + 1}</SoftPagination>)}                                    
                                    <SoftPagination item onClick={() => {if(activePage < paginatedContent.length - 1) {setPage(activePage + 1)}}} disabled={activePage == paginatedContent.length - 1}>
                                        <Icon>keyboard_arrow_right</Icon>
                                    </SoftPagination>
                                </SoftPagination>
                            </div>
                        </>
                    ) : modalContent}
                </Box>
            </Modal>
            <RunnerModal
                flowId={selectedFlow}
                previousRunId={previousRunId}
                setSelectedFlow={setSelectedFlow}
                open={flowRunnerOpen}
                setOpen={setFlowRunnerOpen}
            />
            <DataTable
                table={{
                    rows: data ?? [],
                    columns: columns,
                }}
                isLoading={!data}
                canSearch={true}                
                initialState={{ pagination: { pageIndex: 0, pageSize: 10 }, sorting: [{ id: 'datetime', desc: true }] }}
                initialColumnFilters={runId ? [{ id: 'id', value: runId }] : []}
            />
        </DashboardLayout>
    );
}

export default ResultsUI;