/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import logoSlack from "assets/images/small-logos/logo-slack.png";
import logoDrive from "assets/images/small-logos/logo-google-drive.png";
import logoWordpress from "assets/images/small-logos/logo-wordpress.webp";
import Integration from "./Integration";

function Integrations() {
  return (
    <>
      <Card id="integrations">      
        <SoftBox p={3}>
          <SoftTypography variant="h5">Integrations</SoftTypography>
        </SoftBox>

        <Integration
          name="Slack"
          description="Enable flow integrations in your Slack workspace"
          logo={logoSlack}
          link=""
        />

        <Integration
          name="Wordpress"
          description="Enable flow interactions with your Wordpress site"
          logo={logoWordpress}
          link="/docs/integration/wordpress"
        />

        <Integration
          name="Google Drive"
          description="Enable flow integrations with your Google Drive folder"
          logo={logoDrive}
          link=""
        />
      </Card>
    </>
  );
}

export default Integrations;
