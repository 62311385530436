/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from 'react';
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from 'components/SoftInput';
import SoftAlert from "components/SoftAlert";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Settings page components
import axios, {AxiosError} from 'axios';
import DataTable from "../../../../../components/Tables/DataTable";

import { auth } from "../../../../../utils/firebase.utils";
import { useAuthState } from "react-firebase-hooks/auth";

// formik components
import { Formik, Form, Field } from "formik";
import * as Yup from 'yup';

function UserManagement() {
  const [data, setData] = useState(null);
  const [firebaseUser, loading] = useAuthState(auth);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [inviteUserModalOpen, setInviteUserModalOpen] = useState(false);
  const [inviteError, setInviteError] = useState();
  const [selectedUserId, setSelectedUserId] = useState();

  async function updateData() {
    const managedUsers = await axios.get(`/users/${firebaseUser.uid}/managed-account`);
    console.log(managedUsers);
    setData(managedUsers.data);
  }

  async function removeUser(user_id) {
    console.log("REMOVING USER: ", user_id);
    setConfirmationModalOpen(true);
    setSelectedUserId(user_id);
  }

  async function confirmRemoveUser() {
    console.log("REMOVING USER: ", selectedUserId);
    const res = await axios.post('/users/remove', {
      'user_id': selectedUserId
    });
    setConfirmationModalOpen(false);
    setSelectedUserId();
    updateData();
  }

  async function handleConfirmationCancel() {
    setConfirmationModalOpen(false);
  }

  async function inviteUser(values) {
    try{
      const res = await axios.post('/users/invite', {
        'email': values['email'],
        'uid': firebaseUser.uid
      });
      setInviteError();
      setInviteUserModalOpen(false);
      await updateData();
    }
    catch(error)
    {
      setInviteError(error.response.data);
    }
  }

  async function handleInviteUser() {
    setInviteUserModalOpen(true);
  }

  async function handleInviteUserCancel() {
    setInviteUserModalOpen(false);
  }
  
  useEffect(() => {
      updateData();
  }, [firebaseUser])

  const columns = [
    { accessorKey: 'email', header: 'Email', size: 500 },
    { accessorKey: 'status', header: 'Status', size: 500 },
    { 
        header: "action",
        accessorKey: "action",
        cell: ({ cell, row }) => {
            return <SoftBox display="flex" alignItems="center">
              <SoftBox mx={1}>
                  <SoftTypography 
                    variant="body1" 
                    color="secondary" 
                    sx={{ cursor: "pointer", lineHeight: 0 }} 
                    onClick={() => removeUser(row.original.user_id)}
                  >
                  <Tooltip title="Remove" placement="top">
                      <Icon>delete</Icon>
                  </Tooltip>
                  </SoftTypography>
              </SoftBox>
            </SoftBox>
        },
        enableColumnFilter: false,
    },
  ];

  return (
    <>
      <Card id="user-management"> 
        <Dialog
            open={confirmationModalOpen}
            onClose={handleConfirmationCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Confirmation
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this user?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <SoftButton onClick={handleConfirmationCancel}>
                    Cancel
                </SoftButton>
                <SoftButton onClick={confirmRemoveUser} autoFocus>
                    Yes
                </SoftButton>
            </DialogActions>
        </Dialog>  
        <Dialog
            open={inviteUserModalOpen}
            onClose={handleInviteUserCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">
                Invite User
            </DialogTitle>
            <DialogContent>                    
              {inviteError && 
                <SoftAlert color="error" dismissible>                   
                  <Icon fontSize="medium">error</Icon>&nbsp;
                  <SoftTypography variant="body2" color="white">
                    {inviteError}
                  </SoftTypography>
                </SoftAlert>
              }
              <Formik
                initialValues={{
                  'email': ''
                }}
                enableReinitialize
                onSubmit={inviteUser}
                validationSchema={Yup.object().shape({
                  email: Yup.string().email('Invalid email').required('Required'),
                })}
              >
                {({errors, touched}) => (
                  <Form id="tool-inputs" autoComplete="off">
                    <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                      <SoftBox mt={1} mb={1} lineHeight={0} display="inline-block">
                          <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              textTransform="capitalize"
                          >
                              Email
                          </SoftTypography>
                      </SoftBox>
                      <Field
                        as={SoftInput}
                        type="text"
                        name="email"
                        placeholder="email"
                        required
                      />
                      {errors.email && touched.email ? (
                        <SoftBox ml={1} lineHeight={0} display="inline-block">
                          <SoftTypography
                            component="label"
                            variant="overline"
                            fontWeight="bold"
                            color="error"
                            textTransform="capitalize"
                          >
                            {errors.email}
                          </SoftTypography>
                        </SoftBox>
                      ) : null}
                    </SoftBox>
                    
                    <SoftBox mt={3} width="100%" display="flex" justifyContent="end">
                      <SoftButton onClick={handleInviteUserCancel}>
                          Cancel
                      </SoftButton>
                      <SoftButton variant="gradient" color="dark" type="submit">
                          Send
                      </SoftButton>
                    </SoftBox>
                  </Form>
                  )}
                </Formik>
            </DialogContent>
        </Dialog>     
        <SoftBox p={3}>
          <SoftTypography variant="h5">User Management</SoftTypography>
        </SoftBox>
        <SoftBox component="form" pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <DataTable
                  table={{
                      rows: data ?? [],
                      columns: columns,
                  }}
                  isLoading={!data}
                  canSearch={false}   
                  canAdjustPageSize={false}                 
              />
            </Grid>
          </Grid>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
            flexWrap="wrap"
            marginTop="20px"
          >
            <SoftBox ml="auto">
              <SoftButton onClick={handleInviteUser} variant="gradient" color="dark" size="small" >
                Invite User
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </SoftBox>      
      </Card>
    </>
  );
}

export default UserManagement;
