// FirebaseUI
import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'

// React stuff
import { useEffect } from 'react';

// Authentication layout components
import BasicLayout from "./components/BasicLayout";

// Images
import curved9 from "assets/images/curved-images/curved9.jpg";

// Auth service
import { auth } from "../../utils/firebase.utils";
import axios from 'axios';

function FirebaseSignIn() {
    useEffect(() => {
        const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);

        ui.start('#firebaseui-auth-container', {
            callbacks: {
                signInSuccessWithAuthResult: function(authResult, redirectUrl) {
                  console.log("AUTH RESULT");
                  console.log(authResult);
                    if(authResult.additionalUserInfo.isNewUser)
                    {
                        console.log("new user has signed up");
                        axios.post('/users/create', {
                            'uid': authResult.user.uid,
                            'email':authResult.user.email,
                            'name': authResult.user.displayName
                        }).then((res) => {
                            console.log("NEW USER RES: ", res);
                            return true;
                        });
                    }
                },
                signInFailure: function(error) {
                    console.log("FAILED TO SIGN IN: ", error);
                },
                uiShown: function() {
                  console.log("UI SHOWN");
                    // This is what should happen when the form is full loaded. 
                }
            },
            signInFlow: 'popup',
            // signInSuccessUrl: 'https://platform.promptcore.ai', // This is where should redirect if the sign in is successful.
            signInOptions: [ // This array contains all the ways an user can authenticate in your application. For this example, is only by email.
                {
                    provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                    requireDisplayName: true
                },
                {
                  provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID
                }
            ]
        });
    }, []);

    return (
      <BasicLayout
        title="Welcome!"      
        image={curved9}
      >
        <div id="firebaseui-auth-container"></div>
      </BasicLayout>
    );
}

export default FirebaseSignIn;