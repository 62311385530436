/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Soft UI Dashboard PRO React example components
import Sidenav from "components/Sidenav";
import Configurator from "components/Configurator";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import SignInBasic from "pages/sign-in";
import FirebaseSignIn from "pages/firebase";

// Soft UI Dashboard PRO React routes
import routes from "routes";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brand from "assets/images/LogoPurple.png";
import axios from 'axios';

// Auth service
import { auth } from "./utils/firebase.utils";
import { useAuthState } from "react-firebase-hooks/auth";

axios.defaults.baseURL = process.env.REACT_APP_AXIOS_BASE_URL || "https://api.promptcore.ai";

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const [UIs, setUIs] = useState(null);
  const [firebaseUser, loading] = useAuthState(auth);

  const allowedRoutes = ['tools', 'results'];

  // Setting the dir attribute for the body element
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const referringAccountId = urlParams.get('referringAccountId'); 

    if(referringAccountId)
    {
      const d = new Date();
      const expiryDays = 14;
      d.setTime(d.getTime() + (expiryDays*24*60*60*1000));
      let expires = "expires="+ d.toUTCString();
  
      document.cookie = `referringAccountId=${referringAccountId}; expires=${expires}; path=/`;
    }
  }, []);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    (async function() {      
      if(firebaseUser) {
        const userUIs = await axios.get(`get-user-uis-new/${firebaseUser.uid}`);
        setUIs(userUIs.data);
      }
    })();
  }, [firebaseUser]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {      
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  if(loading === false){
    if (!firebaseUser) {
      return (
        <CacheProvider value={rtlCache}>
          <ThemeProvider theme={themeRTL}>
            <CssBaseline />
            <FirebaseSignIn />
          </ThemeProvider>
        </CacheProvider>
      )
    }
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={brand}
              brandName={''}
              routes={routes.filter((route) => allowedRoutes.includes(route.key))}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />            
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/tools" />} />
        </Routes>
      </ThemeProvider>
    );
  }
}
