/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard PRO React layouts
import ResultsUI from "pages/results";
import DownloaderUI from "pages/downloader";
import ToolsUI from "pages/tools";
import BatchRunUI from "pages/batch-run";
import RunUI from "pages/run";
import TestUI from "pages/tester";
import Settings from "pages/account/settings";
import WordpressIntegrationDocs from "pages/integrations/wordpress/docs";
import NotificationsUI from "pages/notifications";
import Stripe from "pages/stripe";
import StripeEmbedded from "pages/stripe/embedded";
import StripeResults from "pages/stripe/results";
import StripeSubscription from "pages/stripe/subscription";
import StripeInvoices from "pages/stripe/invoices";

// Soft UI Dashboard PRO React icons
import SettingsIcon from "components/Icons/Settings";
import Document from "components/Icons/Document";
import ToolDocs from "pages/tool-docs";

const routes = [
  {
    name: "Tools",
    key: "tools",
    type: "collapse",
    icon: <SettingsIcon size="12px" />,
    route: "/tools",
    component: <ToolsUI />,
    noCollapse: true,
  },  
  {
    name: "Results",
    key: "results",
    type: "collapse",
    route: "/results",
    icon: <Document size="12px" />,
    component: <ResultsUI />,
    noCollapse: true,
  },
  {
    name: "FilteredResults",
    key: "filtered_results",
    type: "collapse",
    route: "/results/:runId",
    icon: <Document size="12px" />,
    component: <ResultsUI />,
    noCollapse: true,
  },  
  {
    name: "Run",
    key: "run",
    type: "collapse",
    icon: <SettingsIcon size="12px" />,
    route: "/tool/:toolId/run",
    component: <RunUI />,
    noCollapse: true,
  },
  {
    name: "Test",
    key: "test",
    type: "collapse",
    icon: <SettingsIcon size="12px" />,
    route: "/tool/:toolId/test",
    component: <TestUI />,
    noCollapse: true,
  },
  {
    name: "BatchRun",
    key: "batchRun",
    type: "collapse",
    icon: <SettingsIcon size="12px" />,
    route: "/tool/:toolId/batchRun",
    component: <BatchRunUI />,
    noCollapse: true,
  }, 
  {
    name: "Download",
    key: "download",
    type: "collapse",
    icon: <SettingsIcon size="12px" />,
    route: "/batch_run_download/:type/:folder/:file",
    component: <DownloaderUI />,
    noCollapse: true,
  },
  {
    name: "Account",
    key: "account",
    type: "collapse",
    icon: <SettingsIcon size="12px" />,
    route: "/account",
    component: <Settings />,
    noCollapse: true,
  },
  {
    name: "Tool Documentation",
    key: "tool-documentation",
    type: "collapse",
    icon: <SettingsIcon size="12px" />,
    route: "/docs/:toolSlug",
    component: <ToolDocs />,
    noCollapse: true,
  },
  {
    name: "Wordpress Integration Docs",
    key: "wordpress-integration-docs",
    type: "collapse",
    icon: <SettingsIcon size="12px" />,
    route: "/docs/integration/wordpress",
    component: <WordpressIntegrationDocs />,
    noCollapse: true,
  },
  {
    name: "Notifications",
    key: "notifications",
    type: "collapse",
    icon: <SettingsIcon size="12px" />,
    route: "/notifications",
    component: <NotificationsUI />,
    noCollapse: true,
  },
  {
    name: "Stripe",
    key: "stripe",
    type: "collapse",
    icon: <SettingsIcon size="12px" />,
    route: "/stripe",
    component: <Stripe />,
    noCollapse: true,
  },
  {
    name: "Stripe Embedded",
    key: "stripe-embedded",
    type: "collapse",
    icon: <SettingsIcon size="12px" />,
    route: "/stripe/embedded",
    component: <StripeEmbedded />,
    noCollapse: true,
  },
  {
    name: "Stripe Results",
    key: "stripe-results",
    type: "collapse",
    icon: <SettingsIcon size="12px" />,
    route: "/stripe/results",
    component: <StripeResults />,
    noCollapse: true,
  },
  {
    name: "Stripe Subscription",
    key: "stripe-subscription",
    type: "collapse",
    icon: <SettingsIcon size="12px" />,
    route: "/stripe/subscription",
    component: <StripeSubscription />,
    noCollapse: true,
  },
  {
    name: "Stripe Invoices",
    key: "stripe-invoices",
    type: "collapse",
    icon: <SettingsIcon size="12px" />,
    route: "/stripe/invoices",
    component: <StripeInvoices />,
    noCollapse: true,
  },
];

export default routes;
