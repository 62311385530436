/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useState } from 'react';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Settings page components
import FormField from "pages/account/components/FormField";
import SoftAlert from "components/SoftAlert";

// Auth service
import { auth } from "../../../../../utils/firebase.utils";
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from "firebase/auth";

function ChangePassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [oldPasswordError, setOldPasswordError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [successfulReset, setSuccessfulReset] = useState(false);

  const validateField = (fieldName, field, setError) => {
      if (field.length < 1) {
          setError(`${fieldName} is required`);
          return false;
      } else {
          setError('');
          return true;
      }
  };

  const updatePasswordFunc = async () => {
    
    if (validateField('Old password', oldPassword, setOldPasswordError) &&
    validateField('New password', newPassword, setNewPasswordError) &&
    validateField('Confirm password', confirmPassword, setConfirmPasswordError)) {
      
      if(newPassword != confirmPassword){
        setConfirmPasswordError('Confirm does not match new password');
        return;
      }
      if(oldPassword === newPassword){
        setConfirmPasswordError('New password cannot be the same as old password');
        return;
      }
      
      const user = auth.currentUser;
      var cred = EmailAuthProvider.credential(user.email, oldPassword);
      reauthenticateWithCredential(user, cred)
        .then(() => {
          updatePassword(user, newPassword)
              .then(() => {
                console.log("Success: Password is updated!");
                
                setOldPasswordError('');
                setNewPasswordError('');
                setConfirmPasswordError('');
                setNewPassword('');
                setConfirmPassword('');
                setOldPassword('');
                setSuccessfulReset(true);   
              })
              .catch((err) => {
                console.error("Error: Password updation failed. " + err.message);
                setNewPasswordError('Failed to update password: ' + err.message);
              });
        })
        .catch((err) => {
          console.error("Error: Invalid password: " + err.message);
          setOldPasswordError('Incorrect password entered');
        });
    }
  }

  const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        updatePasswordFunc();
      }
  };

  return (
    <>
      {successfulReset && (<SoftAlert dismissible>Password Successfully Reset!</SoftAlert>)}
      <Card id="change-password">      
        <SoftBox p={3}>
          <SoftTypography variant="h5">Change Password</SoftTypography>
        </SoftBox>
        <SoftBox component="form" pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField
                label="current password"              
                inputProps={{ type: "password", autoComplete: "" }}
                onKeyDown={handleKeyDown}
                placeholder="Old Password"
                value={oldPassword}
                onChange={(event) => setOldPassword(event.currentTarget.value)}
                error={oldPasswordError}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                label="new password"
                placeholder="New Password"
                value={newPassword}
                onChange={(event) => setNewPassword(event.currentTarget.value)}
                inputProps={{ type: "password", autoComplete: "" }}
                onKeyDown={handleKeyDown}
                error={newPasswordError}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                label="confirm new password"
                placeholder="Confirm New Password"
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.currentTarget.value)}
                inputProps={{ type: "password", autoComplete: "" }}
                onKeyDown={handleKeyDown}
                error={confirmPasswordError}
              />
            </Grid>
          </Grid>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
            flexWrap="wrap"
            marginTop="20px"
          >
            <SoftBox ml="auto">
              <SoftButton variant="gradient" color="dark" size="small" onClick={updatePasswordFunc}>
                update password
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </SoftBox>      
      </Card>
    </>
  );
}

export default ChangePassword;
