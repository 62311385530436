/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Settings page components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import axios from "axios";
import JsonView from "react18-json-view";
// Auth service
import { auth } from "../../../utils/firebase.utils";
import { useAuthState } from "react-firebase-hooks/auth";

function StripeSubscription() {
  const [response, setResponse] = useState();   
    
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    axios.get(`/stripe/subscription?firebase_uid=${user.uid}`)
      .then((res) => {
        console.log(res.data);
        setResponse(res.data);
      });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={4}>
        <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <JsonView 
                    src={response} 
                    theme={'default'} 
                />
            </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default StripeSubscription;
