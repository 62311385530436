/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import Popover from '@mui/material/Popover';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge  from "components/SoftBadge";
import SoftAlert from "components/SoftAlert";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "components/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard PRO React context
import {
  useSoftUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

import breakpoints from "assets/theme/base/breakpoints";

import { auth } from "../../../utils/firebase.utils";
import { useAuthState } from "react-firebase-hooks/auth";
import axios from 'axios';

function DashboardNavbar({ absolute = false, light = false, isMini = false, title = false }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar } = controller;
  const [openMenu, setOpenMenu] = useState(true);
  const route = useLocation().pathname.split("/").slice(1);
  const { values } = breakpoints;

  const [firebaseUser, loading] = useAuthState(auth);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [notificationAlerts, setNotificationAlerts] = useState();

  const [webSocketClient, setWebSocketClient] = useState();

  const menuAnchorEl = useRef(null);

  const { WebPubSubClient } = require("@azure/web-pubsub-client");

  async function startWebSocket() {
      // Instantiate the client object. 
      // <client-access-url> is copied from Azure portal mentioned above.
      const client = new WebPubSubClient({
          getClientAccessUrl: async () => {
              let res = await axios.get(`socket?hub=account_${firebaseUser.uid}`);
            return res.data.url;
          }
        });

      client.start();

      client.joinGroup(`notifications`);

      client.on("server-message", (e) => {
          setNotifications(temp => [...temp, e.message.data]);
          updateNotificationCount();
      });

      client.on("group-message", (e) => {
          updateNotificationCount();
      });

      setWebSocketClient(client);
  }

  async function updateNotificationCount()
  {
    const n = await axios.get(`notifications/${firebaseUser.uid}/count?status=active`);
    setNotificationCount(parseInt(n.data));
  }

  useEffect(() => {
    updateNotificationCount();
    startWebSocket();
  }, []);

  useEffect(() => {
    (async function() {
      const formattedData = [];
      for(const notification of notifications) {
        console.log(notification);
        formattedData.push(                    
          <SoftAlert key={notification.id} color="secondary" dismissible onDismiss={() => onDismiss(notification.id)} link={notification.link ? notification.link : null}>                   
            <Icon fontSize="medium">info</Icon>&nbsp;
            <SoftTypography variant="body2" color="white">
              {notification.message}
            </SoftTypography>
          </SoftAlert>
        );
      }
      setNotificationAlerts(formattedData);
    })();
  }, [notifications]);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  async function onDismiss(alertId) {
      const body = {
          "alertId": alertId
      };
      const res = await axios.post(`notifications/disable`, body);
      updateNotificationCount();
  }

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = (event) => setOpenConfigurator(dispatch, event.currentTarget);

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SoftBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <SoftTypography
            variant={window.innerWidth < values.sm ? "h3" : "h2"}
            textTransform="capitalize"
            fontWeight="bold"
          >
            {title ? title : route[route.length - 1]}
          </SoftTypography>
        </SoftBox>
        {isMini ? null : (
          <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
            <SoftBox color={light ? "white" : "inherit"}>              
              <IconButton sx={navbarIconButton} size="small" style={{ cursor: 'default' }}>
                <Icon
                  sx={({ palette: { dark, white } }) => ({
                    color: light ? white.main : dark.main,
                  })}
                >
                  account_circle
                </Icon>
                <SoftTypography
                  variant="button"
                  fontWeight="medium"
                  color={light ? "white" : "dark"}
                >
                  {firebaseUser?.displayName}
                </SoftTypography>
              </IconButton>              
              <IconButton
                size="small"
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon className={light ? "text-white" : "text-dark"}>
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon>settings</Icon>
              </IconButton>
              <IconButton
                component={Link} 
                to="/notifications"
                ref={menuAnchorEl}
                size="medium"
                color="inherit"
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
              >
                <Icon className={light ? "text-white" : "text-dark"}>notifications</Icon>
                {notificationCount > 0 &&
                  <SoftBadge color="error" badgeContent={notificationCount} circular indicator size="xs" container sx={{marginLeft:'-10px', marginTop:'-13px'}} />
                }
              </IconButton>
              {notificationAlerts && 
                <SoftBox
                  sx={{ mt: 2, background: 'none', position: 'absolute', width: "400px", marginLeft: '-170px', marginTop: '25px' }}
                >
                  {notificationAlerts}
                </SoftBox>
              }
            </SoftBox>
          </SoftBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  title: PropTypes.any,
};

export default DashboardNavbar;
