import { useEffect, useState } from 'react';
import axios from 'axios';
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import ComplexProjectCard from "components/Cards/ComplexProjectCard";
import logoPromptcore from "assets/images/small-logos/promptcore-logo.png";
import productResearch from "assets/images/tool-logos/Product-Research.png";
import reply from "assets/images/tool-logos/Reply.png";
import retrieve from "assets/images/tool-logos/Retrieve.png";
import scholarlyUrlFinder from "assets/images/tool-logos/Scholarly-URL-Finder.png";
import searchRag from "assets/images/tool-logos/SearchRAG.png";
import socialReactionBroad from "assets/images/tool-logos/Social-Reaction---Broad.png";
import socialReactionListicle from "assets/images/tool-logos/Social-Reaction---Listicle.png";
import socialReactionStandard from "assets/images/tool-logos/Social-Reaction---Standard.png";
import writeArticle from "assets/images/tool-logos/Write-Article.png";
import writeListicle from "assets/images/tool-logos/Write-Listicle.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import moment from 'moment-timezone';
// Auth service
import { auth } from "../../utils/firebase.utils";
import { useAuthState } from "react-firebase-hooks/auth";

function ToolsUI() {
    const [data, setData] = useState(null);    
    const [toolMenu, setToolMenu] = useState({});
    const toolLogos = {
        productResearch,
        reply,
        retrieve,
        scholarlyUrlFinder,
        searchRag,
        socialReactionBroad,
        socialReactionListicle,
        socialReactionStandard,
        writeArticle,
        writeListicle
    }
    const navigate = useNavigate();

    const openToolMenu = (event, toolId) => setToolMenu({ ...toolMenu, [toolId]: event.currentTarget });
    const closeToolMenu = () => setToolMenu({});    
    
    const [user, loading] = useAuthState(auth);

    useEffect(() => {
        (async function() {
          const flows = await axios.get(`flows/${user.uid}/list`);
          const lastRunsResponse = await axios.get(`flows/lastRun/${user.uid}`)          
          const formattedFlows = [ ...flows.data ]
          if(lastRunsResponse) {
            const lastRunDict = {}
            for (const lastRun of lastRunsResponse.data) {
                lastRunDict[lastRun.tool_id] = { runId: lastRun.id, datetime: moment(new Date(lastRun.datetime)).format("DD.MM.YY") }
            }
            for (const flow of formattedFlows) {                
                flow.lastRun = lastRunDict[flow.id] ?? null;                
            }
          }          
          setData(formattedFlows);
        })();
    }, [])

    const renderMenu = (state, close, toolId, slug) => (
        <Menu
          anchorEl={state}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={Boolean(state)}
          onClose={close}
          keepMounted
        >
          <MenuItem onClick={() => {close; navigate(`/tool/${toolId}/run`)}}>Single Run</MenuItem>
          <MenuItem onClick={() => {close; navigate(`/tool/${toolId}/batchRun`)}}>Batch Run</MenuItem>
          <MenuItem onClick={() => {close; navigate(`/docs/${slug}`)}}>Documentation</MenuItem>
        </Menu>
      );

    return (
        <DashboardLayout>
            <DashboardNavbar title="Tools" />
            <Grid container spacing={3}>
                {data && data.map((tool, i) => (                
                    <Grid item xs={12} md={6} lg={4} key={`tool-card-grid-${tool.id}`}>
                        <ComplexProjectCard
                            image={toolLogos[tool.icon_url] ?? logoPromptcore}
                            key={`tool-card-${tool.id}`}
                            title={tool.name}
                            toolId={tool.id}
                            description={tool.description}                            
                            lastRun={tool.lastRun ?? null}
                            avgRunTime={`${Math.floor(tool.avg_run_time / 60) > 0 ? `${Math.floor(tool.avg_run_time / 60)}:${tool.avg_run_time % 60 < 10 ? '0' + tool.avg_run_time % 60 : tool.avg_run_time % 60}` : tool.avg_run_time}`}                      
                            dropdown={{
                                action: (e) => {openToolMenu(e, tool.id)},
                                menu: renderMenu(toolMenu[tool.id], closeToolMenu, tool.id, tool.slug),
                            }}
                        />
                    </Grid>                
                ))}
            </Grid>
        </DashboardLayout>
    );
}

export default ToolsUI;