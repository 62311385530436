/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import DashboardNavbar from "components/Navbars/DashboardNavbar";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import ToolRunForm from "../../components/ToolRunForm";
import RedditPostSelector from "../../components/RedditPostSelector";
import ScholarlyURLSelector from "../../components/ScholarlyURLSelector";

import Icon from "@mui/material/Icon";
import Link from '@mui/material/Link';
import SoftTypography from "components/SoftTypography";

import CircularProgress from '@mui/material/CircularProgress';

import {useParams} from "react-router-dom";
import axios from 'axios';

function RunUI() {
  const [isRedditFlow, setIsRedditFlow] = useState(false);

  const {toolId} = useParams();
  const [tool, setTool] = useState({});
  const [permalink, setSelectedPermalink] = useState();
  const [scholarlyUrls, setScholarlyUrls] = useState();
  const [loading, setLoading] = useState(false);
  const [webSocketClient, setWebSocketClient] = useState();

  const { WebPubSubClient } = require("@azure/web-pubsub-client");

  useEffect(() => {
    if(toolId) {
        (async function() {
            const tool = await axios.get(`tools/${toolId}`);            
            setTool(tool.data);
            setIsRedditFlow(!!tool.data.reddit_flow);
        })();
    }
  }, [toolId]);
  
  useEffect(() => {
    startWebSocket();
  }, []);

  async function startWebSocket() {
    // Instantiate the client object. 
    // <client-access-url> is copied from Azure portal mentioned above.
    const client = new WebPubSubClient({
        getClientAccessUrl: async () => {
          let value = await axios.get(`socket?hub=results`);
          return value.data.url;
        }
      });

    client.start();

    client.joinGroup("ResultsUpdate");

    setWebSocketClient(client);
  }

  const toolRunFormOnSubmitQueueRun = async (data) => {
    await axios.post('/flows/run', data);
    webSocketClient.sendToGroup("ResultsUpdate", "update", "text");
  }

  const toolRunFormOnSubmitTriggerRun = async (data) => {
    setLoading(true);
    let vars = JSON.parse(data['variables']);
    let date_filter = {};
    if('start_year' in vars)
    {
      if(typeof(vars['start_year']) == 'number')
      {
        date_filter['as_ylo'] = vars['start_year'];
      }
      delete vars['start_year'];
    }
    if('end_year' in vars)
    {
      if(typeof(vars['end_year']) == 'number')
      {
        date_filter['as_yhi'] = vars['end_year'];
      }
      delete vars['end_year'];
    }
    if(Object.keys(date_filter).length > 0)
    {
      vars['date_filter'] = JSON.stringify(date_filter);
    }

    data['variables'] = JSON.stringify(vars);

    const res = await axios.post('/flows/trigger', data);
    let parsed = JSON.parse(res.data['rate_text']);
    let urls = parsed['results'];
    urls.sort((a,b) => b.rating - a.rating); // b - a for reverse sort
    console.log(urls)
    setScholarlyUrls(urls);
    setLoading(false);
  }
  
  function getContent() {  
    if(isRedditFlow)
    {
      return (
        <Grid item xs={12}>
          <Card sx={{ overflow: "visible" }}>
            <SoftBox p={2}>
              <SoftBox pl={1} position="absolute" top="25px" right="32px">
                <SoftTypography variant="Overline" color="secondary">   
                    <Link href="/tools">
                      <Icon>close</Icon>
                    </Link>
                  </SoftTypography>
                </SoftBox>
                <SoftBox>
                  <RedditPostSelector 
                    flowId={tool?.flow_id ?? 1}
                    setSelectedPermalink={setSelectedPermalink}
                  />
              </SoftBox>
            </SoftBox>
          </Card>
        </Grid>
      );
    }
    else if(toolId == 11)
    {
      return (
        <Grid item xs={12}>
          <Card sx={{ overflow: "visible" }}>
            <SoftBox p={2}>
              <SoftBox pl={1} position="absolute" top="25px" right="32px">
                <SoftTypography variant="Overline" color="secondary">   
                    <Link href="/tools">
                      <Icon>close</Icon>
                    </Link>
                  </SoftTypography>
                </SoftBox>
                {loading &&
                  <SoftBox  display="flex" alignItems="center" justifyContent="center" height="500px">
                    <CircularProgress color="inherit" style={{ marginLeft: 10}} />
                  </SoftBox>
                }
                {!scholarlyUrls && !loading &&
                  <SoftBox>
                      <ToolRunForm 
                        flowId={tool?.flow_id ?? 1}
                        onSubmit={toolRunFormOnSubmitTriggerRun}
                      />
                  </SoftBox>
                } 
                {scholarlyUrls && !loading &&
                  <SoftBox>
                    <ScholarlyURLSelector 
                      flowId={166}
                      urls={scholarlyUrls}
                    />
                  </SoftBox>
                }
            </SoftBox>
          </Card>
        </Grid>
      );
    }
    else
    {
      return (
        <Grid item xs={12} sm={12} md={11} lg={10} xl={8} xxl={8}>
          <Card sx={{ overflow: "visible" }}>
            <SoftBox p={2}>
              <SoftBox pl={1} position="absolute" top="25px" right="32px">
                <SoftTypography variant="Overline" color="secondary">   
                    <Link href="/tools">
                      <Icon>close</Icon>
                    </Link>
                  </SoftTypography>
                </SoftBox>
                <SoftBox>
                  <ToolRunForm 
                    flowId={tool?.flow_id ?? 1}
                    selectedPermalink={permalink}
                    isRedditFlow={isRedditFlow}
                    onSubmit={toolRunFormOnSubmitQueueRun}
                  />
              </SoftBox>
            </SoftBox>
          </Card>
        </Grid>
      );
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar title={tool.name} />
      <SoftBox mt={1} mb={20}>
        <Grid container justifyContent="center">
          {getContent()}
        </Grid>
      </SoftBox>      
    </DashboardLayout>
  );
}

export default RunUI;
