import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftAvatar from "components/SoftAvatar";
import PropTypes from "prop-types";

function Integration({name, description, logo, link}) {
    return (
        <SoftBox p={1}>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center">
            <SoftBox display="flex" justifyContent="space-between" alignItems="center">
              
              <SoftBox p={2} >
                <SoftAvatar src={logo} alt="Slack" size="md" variant="rounded" />
              </SoftBox>
              <SoftBox ml={1} lineHeight={0} mr={2}>
                <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    {name}
                </SoftTypography>
              </SoftBox>
              <SoftTypography variant="body2" color="text">
                {description}
              </SoftTypography>
            </SoftBox>
            
            <SoftBox display="flex" justifyContent="end" alignItems="center" mr={2}>
              <SoftButton
                component="a"
                href={link}
                variant="contained"
                color="dark"
                size="small"
                disabled={link == ""}
              >
                Add
              </SoftButton>
            </SoftBox>
          </SoftBox>  
        </SoftBox>   
    );
} 


Integration.propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
    logo: PropTypes.any,
    link: PropTypes.string,
}
        
export default Integration;