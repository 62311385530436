import { useEffect, useState } from 'react';
import axios from 'axios';
import CheckIcon from '@mui/icons-material/Check';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DataTable from "../Tables/DataTable";
import moment from 'moment';
import SoftButton from "../SoftButton";
import SoftBox from '../SoftBox';
import SoftCreatableSelect from '../SoftCreatableSelect';
import SoftTypography from '../SoftTypography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import RunnerModal from 'components/RunnerModal';

function RedditPostFetcher({ flowId }) {
    const [data, setData] = useState(null);
    const [posts, setPosts] = useState(null);
    const [subreddits, setSubreddits] = useState([]);
    const [redditPostButtons, setRedditPostButtons] = useState([]);
    const [selectedSubreddit, setSelectedSubreddit] = useState(null);
    const [selectedPost, setSelectedPost] = useState();
    const [flowConfig, setFlowConfig] = useState({});
    const [page, setPage] = useState(0);

    const [runPostIds, setRunPostIds] = useState([]);
    
    const [flowRunnerOpen, setFlowRunnerOpen] = useState(false);

    const [newSubreddit, setNewSubreddit] = useState();
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [secondConfirmationModalOpen, setSecondConfirmationModalOpen] = useState(false);
    const [backfillingNewSubreddit, setBackfillingNewSubreddit] = useState(false);

    const [permalink, setSelectedPermalink] = useState();

    const columns = [
        { 
            accessorKey: 'title',
            header: 'Title',
            cell: ({ cell, row }) => {
                return <>{row.original.title}</>
            },
         },
        { accessorKey: 'comments', header: 'Comments' },
        { accessorKey: 'score', header: 'Score' },
        { accessorKey: 'age', header: 'Age' },
        { 
            accessorKey: 'flow_button',
            header: 'Select',
            cell: ({ cell, row }) => {
                return <>{row.original.flow_button}</>
            },
        },
    ];

    const handleSelectPost = (postId, permalink) => {
        setSelectedPost(postId);
        setSelectedPermalink(permalink);
        setFlowRunnerOpen(true);
    }

    const handleNewSubreddit = (subreddit) => {
        setNewSubreddit(subreddit);
        setConfirmationModalOpen(true);
    }

    const handleConfirmationCancel = () => {
        setConfirmationModalOpen(false);
        setNewSubreddit(null);
    }

    const handleConfirmationConfirm = () => {
        setSecondConfirmationModalOpen(true);
        setConfirmationModalOpen(false);
    }

    const handleSecondConfirmationCancel = () => {
        setSecondConfirmationModalOpen(false);
        setNewSubreddit(null);
    }

    const onRunnerModalSubmit = () => {
        console.log("onRunnerModalSubmit");
        let updatedRunPostIds = runPostIds;
        updatedRunPostIds.push(selectedPost);
        setRunPostIds(updatedRunPostIds);
        setFlowRunnerOpen(false);
        setSelectedPost(null);
        setSelectedPermalink(null);
    }

    const onRunnerModalCancel = () => {
        console.log("onRunnerModalCancel");
        setFlowRunnerOpen(false);
        setSelectedPost(null);
        setSelectedPermalink(null);
        
    }

    const handleSecondConfirmationConfirm = async () => {
        setSecondConfirmationModalOpen(true);
        const data = {
            subreddit: newSubreddit
        }        
        try {
            const res = await axios.post('/reddit/subreddit', data);
            setBackfillingNewSubreddit(true);        
            setSubreddits([ ...subreddits, { value: res.data?.id.toString(), label: data.subreddit } ]) 
            setTimeout(
                () => {
                    setSelectedSubreddit({ value: res.data?.id.toString(), label: data.subreddit });
                    handleSecondConfirmationCancel();
                    setBackfillingNewSubreddit(false);
                },
                150000
            )
        }
        catch {
            handleSecondConfirmationCancel();
            alert('Subreddit already exists in db');
        }
    }

    useEffect(() => {
        if(flowId)
        {
            (async function() {
                const flowConfigResponse = await axios.get(`reddit/flows/${flowId}`);                
                setFlowConfig(flowConfigResponse.data);
            })();
        }
        (async function() {
            const subredditResponse = await axios.get('reddit/subreddits');
            const formattedSubreddits = [];
            for(const subreddit of subredditResponse.data) {
                formattedSubreddits.push({ value: subreddit.id.toString(), label: subreddit.subreddit })
            }
            setSubreddits(formattedSubreddits);            
        })();
    }, [flowId])

    useEffect(() => {
        if(posts?.data && redditPostButtons) {
            (async function() {
                const formattedData = [];
                for(const post of posts.data) {
                    const postButton =
                        <SoftButton 
                            variant="gradient" 
                            color="primary"
                            onClick={() => {handleSelectPost(post.id, post.permalink)}} 
                            className={'flow-run-submit-button'}
                            sx={{ width: 128 }}
                            key={`reddit-flow-runner-button-${post.id}`} 
                            disabled={runPostIds.includes(post.id)} 
                            endIcon={runPostIds.includes(post.id) && <CheckIcon size={18} />} 
                        >
                             Run
                        </SoftButton>
                    let age = null;
                    if (post.created_datetime) {
                        age = moment().diff(post.created_datetime, 'hours')
                        if(age > 24) {
                            age = `${Math.floor(age / 24)}d`
                        } else {
                            age = `${age}h`
                        }
                    }

                    const titleLink = 
                        <SoftTypography 
                            variant="caption" 
                            component="a" 
                            target="_blank" 
                            href={post.permalink} 
                            fontWeight="medium" 
                            color="primary"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            {post.title.length > 120 ? post.title.substring(0,120) + "..." : post.title}
                            <OpenInNewIcon sx={{ marginLeft: "5px"}}/>
                        </SoftTypography>

                    formattedData.push({
                        id: post.id,
                        title: titleLink,
                        flow_button: postButton,
                        comments: post.comments ?? null,
                        score: post.score ?? null,
                        age: age ?? null,
                    })
                }
                setData(formattedData);
            })();
        }
    }, [posts, redditPostButtons, flowId, setSelectedPermalink, selectedPost, runPostIds])

    useEffect(() => {
        if(flowId && selectedSubreddit) {
            (async function() {
                const postsResponse = await axios.get(`reddit/posts/${flowId}/${selectedSubreddit.value}/${page}`);
                const postsButtons = {};
                for(const row of postsResponse.data) {
                    postsButtons[row.id] = { disabled: false };
                }
                setPosts(postsResponse);
                setRedditPostButtons(postsButtons);
            })();
        }
    }, [flowId, page, selectedSubreddit])

    useEffect(() => {
        setPage(0);
    }, [selectedSubreddit])

    return (
        <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
            <SoftBox mt={1} mb={1} lineHeight={0} display="inline-block">
                <SoftTypography
                    component="label"
                    variant="subtitle2"
                    fontWeight="bold"
                    textTransform="capitalize"
                >
                    {flowConfig.instructions ?? "Subreddit"}
                </SoftTypography>
            </SoftBox>
            <Grid item xs={12} md={3} lg={3}>
                <SoftCreatableSelect
                    options={subreddits ?? []}
                    onChange={setSelectedSubreddit}
                    value={selectedSubreddit}
                    isClearable
                    size="small"
                    onCreateOption={(v) => handleNewSubreddit(v)}
                />
            </Grid>
            <Dialog
                open={confirmationModalOpen}
                onClose={handleConfirmationCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirmation
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to insert {newSubreddit} into Promptcore&apos;s monitoring tables?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <SoftButton onClick={handleConfirmationCancel}>
                        Cancel
                    </SoftButton>
                    <SoftButton onClick={handleConfirmationConfirm} autoFocus>
                        Yes
                    </SoftButton>
                </DialogActions>
            </Dialog>
            <Dialog
                open={secondConfirmationModalOpen}
                onClose={handleSecondConfirmationCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirmation
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">                        
                        {backfillingNewSubreddit ? (<div style={{ display: 'flex', alignItems: 'center' }}>Backfilling takes ~2 minutes <CircularProgress color="inherit" style={{ marginLeft: 10}} /></div >) : 'Are you absolutely sure?'}
                    </DialogContentText>
                </DialogContent>
                {!backfillingNewSubreddit && (
                    <DialogActions>
                        <SoftButton onClick={handleSecondConfirmationCancel}>
                            Cancel
                        </SoftButton>
                        <SoftButton onClick={handleSecondConfirmationConfirm} autoFocus>
                            Yes
                        </SoftButton>
                    </DialogActions>
                )}
            </Dialog>
            <RunnerModal
                flowId={flowId}
                open={flowRunnerOpen}
                onClose={onRunnerModalCancel}
                onSubmit={onRunnerModalSubmit}
                initialValuesOverride={{'permalink': permalink}}
            />
            {data && (
            <>
                <DataTable
                    table={{
                        rows: data ?? [],
                        columns: columns,
                    }}
                    isLoading={!setBackfillingNewSubreddit}
                    canSearch={false}      
                    canAdjustPageSize={false}              
                />
                <SoftButton
                    variant="filled"
                    style={{ marginTop: 20, marginBottom: 20 }}
                    onClick={(event) => {
                        event.preventDefault();
                        setPage(page + 1);
                    }}
                >
                    10 New Posts
                </SoftButton>
            </>)}
        </SoftBox>
    )
}

RedditPostFetcher.propTypes = {
    flowId: PropTypes.number.isRequired,
    setSelectedPermalink: PropTypes.func,
}

export default RedditPostFetcher;